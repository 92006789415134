import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faArrowRight,
  faGlobe,
  faCheck,
  faFilter
} from '@fortawesome/free-solid-svg-icons'
import { RangeSlider, SelectPicker, Modal, Button } from 'rsuite'
import { useSelector, useDispatch } from 'react-redux'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import Layout from '../../Components/Layout/Layout'
import moment from 'moment'
import Axios from 'axios'
import { CurrencyConverter } from '../../Components/GlobalData/GlobalData'

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import Loading from '../../Components/Loading/Loader'
import {
  setSearchQuery,
  setHotelDetail,
  setSearchResults
} from '../../Redux/hotelActions'
function Hotels () {
  const [isloading, setIsLoading] = useState(true)
  const [orderBy, setOrderBy] = useState(1)
  const [searchData, setSearchData] = useState({})
  const [numberOfNights, setNumberOfNights] = useState('')
  const [currencyRates, setCurrencyRates] = useState([])
  const [hotelsDetail, setHotelsDetail] = useState({})
  const [priceConversion, setPriceConversion] = useState(false)
  const [hotelsResponse, setHotelsResponse] = useState([])
  const [hotelsList, setHotelsList] = useState([])
  const [filteropen, setFilterOpen] = React.useState(false)
  const [overflow, setOverflow] = React.useState(true)
  const [resetHotelsList, setResetHotelsList] = useState([])
  const location = useLocation()
  const baseurl = ApiEndPoint()
  const token = Hotelapitoken()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const BaseCurrRates = useSelector(state => state.currency.baseCurrRates)
  const SelectedCurrRates = useSelector(
    state => state.currency.selectedCurrRates
  )
  //Filters
  const [hotelsFList, setHotelsFList] = useState([])
  const [selectedhotelid, setSelectedhotelid] = useState(null)
  const [selectedStars, setSelectedStars] = useState([])
  const [selectedMeals, setSelectedMeals] = useState([])
  const [rangeValue, setRangeValue] = useState([0, 0])
  const [minimumPrice, setMinimumPrice] = useState(0)
  const [maximumPrice, setMaximumPrice] = useState(0)
  // *******************************************************
  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const HotelPerPage = 15 // Number of hotels to display per page
  const indexOfLastHotel = currentPage * HotelPerPage
  const indexOfFirstHotel = indexOfLastHotel - HotelPerPage
  const pagesCount = Math.ceil(hotelsList.length / HotelPerPage)
  const validCurrentPage = currentPage < 1 ? 1 : currentPage
  const currentHotels = hotelsList.slice(indexOfFirstHotel, indexOfLastHotel)
  const maxPagesToShow = 8
  const startHotelIndex = indexOfFirstHotel + 1
  const endHotelIndex = Math.min(indexOfLastHotel, hotelsList.length)
  const paginate = pageNumber => {
    window.scrollTo(0, 0)
    setCurrentPage(pageNumber)
  }

  const renderPaginationItems = () => {
    const items = []
    const maxPagesToShow = 5 // Number of pages to show at once

    // Calculate the start page
    let startPage = currentPage - Math.floor(maxPagesToShow / 2)
    if (startPage < 1) {
      startPage = 1
    }

    // Calculate the end page
    let endPage = startPage + maxPagesToShow - 1
    if (endPage > pagesCount) {
      endPage = pagesCount
      startPage = Math.max(endPage - maxPagesToShow + 1, 1)
    }

    // Generate page items
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li class={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
          <a class='page-link' onClick={() => paginate(i)} href='#'>
            {i}
          </a>
        </li>
      )
    }

    return items
  }

  useEffect(() => {
    if (currentPage < 1) {
      setCurrentPage(1)
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount)
    }
  }, [pagesCount])

  // *************************************************************

  // ********************************************************

  useEffect(() => {
    setIsLoading(true)
    Searchhotels()
  }, [location.search])
  const Searchhotels = async () => {
    setOrderBy('1')
    const urlParams = new URLSearchParams(window.location.search)
    const parsedData = {}
    for (const [key, value] of urlParams.entries()) {
      if (key === 'Adults' || key === 'children' || key === 'rooms_counter') {
        parsedData[key] = value.split(',').map(Number)
      } else if (key === 'lat' || key === 'long') {
        parsedData[key] = parseFloat(value)
      } else if (key === 'adult' || key === 'child' || key === 'room') {
        parsedData[key] = parseInt(value, 10)
      } else if (key.startsWith('child_ages')) {
        // Check for child ages
        const roomIndex = parseInt(key.replace('child_ages', ''), 10) - 1 // Get the room index
        parsedData[key] = value ? value.split(',').map(Number) : [] // Split and convert ages to numbers or set to empty array
      } else {
        parsedData[key] = value
      }
    }
    parsedData.token = token
    parsedData.currency_slc = 'AFN'
    parsedData.currency_slc_iso = 'AF'
    setSearchData(parsedData)
    let Checkin = moment(parsedData.check_in)
    let checkout = moment(parsedData.check_out)
    let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
    setNumberOfNights(daysBetween)
    dispatch(setSearchQuery(parsedData))
    try {
      const response = await Axios.post(
        baseurl + '/api/search/hotels/new_Live',
        parsedData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (response.data.status == 'success') {
        response.data.hotels_list = response.data.hotels_list.filter(
          item => item.hotel_provider !== 'Custome_hotel'
        )
        const customhotel =[]
        // const customhotel = response.data.hotels_list.filter(
        //   item => item.hotel_provider === 'Custome_hotel'
        // )

        const thirdparty = response.data.hotels_list.filter(
          item => item.hotel_provider !== 'Custome_hotel'
        )
        const flist = response.data.hotels_list.map(item => ({
          label: item.hotel_name,
          value: item.hotel_id
        }))
        setHotelsFList(flist)
        const prices = response.data.hotels_list.map(item => item.min_price)
        const minPrice = Math.min(...prices)
        setMinimumPrice(minPrice)
        const maxPrice = Math.max(...prices)
        setMaximumPrice(maxPrice)
        setRangeValue([minPrice, maxPrice])
        const currencies = []
        response.data.hotels_list.forEach(hotel => {
          if (!currencies.includes(hotel.hotel_curreny)) {
            currencies.push(hotel.hotel_curreny)
          }
        })
        const currencyResponse = await AllCurrency(currencies)
        if (currencyResponse === null || currencyResponse.length === 0) {
          setCurrencyRates([])
          setPriceConversion(false)
          localStorage.setItem('AllHotelCurr', null)
        } else {
          localStorage.setItem('AllHotelCurr', JSON.stringify(currencyResponse))
          setCurrencyRates(currencyResponse)
          setPriceConversion(true)
        }
        setHotelsResponse(response.data.hotels_list)
        setIsLoading(false)
        const sortedHotels = thirdparty.sort(
          (a, b) => a.min_price - b.min_price
        )
        const sortedcustomHotels = customhotel.sort(
          (a, b) => a.min_price - b.min_price
        )
        sortedcustomHotels.sort((a, b) => {
          if (a.client_Id === 48) return -1 // `a` comes first if `cline_id` is 48
          if (b.client_Id === 48) return 1 // `b` comes first if `cline_id` is 48
          return a.client_Id - b.client_Id // Otherwise, sort in ascending order
        })
        const combinedHotels = [...sortedcustomHotels, ...sortedHotels]
        setHotelsList(combinedHotels)
        setResetHotelsList(combinedHotels)
      }
    } catch (error) {
      console.error('Error:', error)
      setIsLoading(false)
    }
  }
  const AllCurrency = async currencyArray => {
    const token = CurrencyConverter()

    if (currencyArray.length === 0) {
      return [] // Return an empty array if no currencies are provided
    }

    const requests = currencyArray.map(currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      }

      return Axios.request(config)
        .then(response => {
          return response.data // Return the response data on success
        })
        .catch(error => {
          console.error(error)
          return null // Return null for failed requests
        })
    })

    const results = await Promise.all(requests)
    const newdatcurr = results.filter(response => response !== null) // Filter out null responses
    return newdatcurr
  }
  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem('selectedCurrency')
    var currencyData = currencyRates
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )

      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  useEffect(() => {
    currentHotels.forEach(hotel => {
      if (!hotelsDetail[hotel.hotel_id]) {
        fetchhotelsDetail(hotel.hotel_id, hotel.hotel_provider)
      }
    })
  }, [currentPage, hotelsList, orderBy])
  const fetchhotelsDetail = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: token
    }
    try {
      const response = await Axios.post(
        baseurl + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )

      setHotelsDetail(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  const RoomDetailPage = async (id, index) => {
    const hotelRoomdetail = hotelsResponse.filter(item => item.hotel_id == id)
    dispatch(setSearchResults(hotelRoomdetail))
    setIsLoading(true)
    try {
      const data = {
        token: token,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        baseurl + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      setIsLoading(false)
      if (response.data !== '') {
        dispatch(setHotelDetail(response.data.hotel_details))
        navigate(`/hotel_detail/${id}`, { state: { index } })
      } else {
        toast.info(
          'Something went wrong. Please select another hotel or try after some time thanks.'
        )
      }
      // Handle the API response here
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const calculateMarkup = (ctype, cvalue, atype, avalue, price) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(avalue) !== 0) {
      if (atype === 'Percentage') {
        markupprice = (price * Number(avalue)) / 100
      } else {
        markupprice = Number(avalue)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    if (Number(cvalue) !== 0) {
      if (ctype === 'Percentage') {
        markupprice = (Number(price) * Number(cvalue)) / 100
      } else {
        markupprice = Number(cvalue)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }

    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2)
    }
    return finalpricemarkup
  }
  const handlenamefilterchnage = value => {
    handleClose();
    setSelectedhotelid(value)
    if (value !== null) {
      const filterhotel = hotelsResponse.filter(item => item.hotel_id === value)
      setHotelsList(filterhotel)
    } else {
      const sortedHotels = hotelsResponse.sort(
        (a, b) => a.min_price - b.min_price
      )
      setHotelsList(sortedHotels)
    }
  }

  // Filter Functions
  const handleStarsChange = star => {
    setSelectedStars(
      prevSelectedStars =>
        prevSelectedStars.includes(star)
          ? prevSelectedStars.filter(s => s !== star) // Remove if already selected
          : [...prevSelectedStars, star] // Add if not selected
    )
  }
  const handleMealTypeChange = meal => {
    setSelectedMeals(
      prevSelectedStars =>
        prevSelectedStars.includes(meal)
          ? prevSelectedStars.filter(s => s !== meal) // Remove if already selected
          : [...prevSelectedStars, meal] // Add if not selected
    )
  }
  const handleOrderTypeChange = event => {
    handleClose();
    setOrderBy(event.target.value)
    let filteredHotels = hotelsResponse
    // Filter by price Low to High
    if (event.target.value === '1') {
      filteredHotels = filteredHotels.sort((a, b) => a.min_price - b.min_price)
    }
    // Filter by price Highy to Low
    if (event.target.value === '2') {
      filteredHotels = filteredHotels.sort((a, b) => b.min_price - a.min_price)
    }
    // Filter by price A-Z
    if (event.target.value === '3') {
      filteredHotels = filteredHotels.sort((a, b) =>
        a.hotel_name.localeCompare(b.hotel_name)
      )
    }
    // Filter by price Z-A
    if (event.target.value === '4') {
      filteredHotels = filteredHotels.sort((a, b) =>
        b.hotel_name.localeCompare(a.hotel_name)
      )
    }
    if (event.target.value === '5') {
      const today = new Date() // Get today's date
      filteredHotels = filteredHotels.filter(hotel => {
        return hotel.rooms_options.some(room =>
          room.cancliation_policy_arr.some(policy => {
            const fromDate = new Date(policy.from_date)
            return fromDate > today // Check if from_date is greater than today
          })
        )
      })
    }
    const customhotel = filteredHotels.filter(
      item => item.hotel_provider === 'Custome_hotel'
    )
    const thirdparty = filteredHotels.filter(
      item => item.hotel_provider !== 'Custome_hotel'
    )
    const combinedHotels = [...customhotel, ...thirdparty]
    setHotelsList(combinedHotels)
  }
  const applyAllFilters = () => {
    handleClose();
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    let filteredHotels = hotelsResponse
    // Filter by price range
    if (rangeValue.length > 0) {
      filteredHotels = filteredHotels.filter(hotel => {
        const price = hotel.min_price
        return price >= rangeValue[0] && price <= rangeValue[1]
      })
    }

    // Filter by star rating
    if (selectedStars.length > 0) {
      filteredHotels = filteredHotels.filter(hotel =>
        selectedStars.includes(hotel.stars_rating)
      )
    }

    // Filter by meal type
    if (selectedMeals.length > 0) {
      filteredHotels = filteredHotels.filter(hotel => {
        return hotel.rooms_options.some(room =>
          selectedMeals.includes(room.board_id)
        )
      })
    }
    const customhotel = filteredHotels.filter(
      item => item.hotel_provider === 'Custome_hotel'
    )
    const thirdparty = filteredHotels.filter(
      item => item.hotel_provider !== 'Custome_hotel'
    )
    const combinedHotels = [...customhotel, ...thirdparty]
    setHotelsList(combinedHotels)
  }
  const ResetFilter = () => {
    handleClose();
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    setHotelsList(resetHotelsList)
    setSelectedhotelid(null)
    setSelectedStars([])
    setSelectedMeals([])
    setOrderBy(1)
  }
  const handleClose = () => {
    setFilterOpen(false)
  }
  return (
    <>
      <ToastContainer />
      {isloading && <Loading />}
      <Modal overflow={overflow} open={filteropen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='px-2'>
            <div className='page-sidebar'>
              <hr style={{ opacity: '1' }} />
              <div className='widget widget_price_filter'>
                <h3 className='form-label'>Order By</h3>
                <select
                  value={orderBy}
                  onChange={handleOrderTypeChange}
                  class='form-select'
                >
                  <option value='1'>Low to High</option>
                  <option value='2'>Highy to Low</option>
                  <option value='3'>Order by A-Z</option>
                  <option value='4'>Order by Z-A</option>
                </select>
              </div>
              <hr style={{ opacity: '1' }} />
              <div className='widget widget_price_filter'>
                <h3 className='form-label'>Search by property name</h3>
                <SelectPicker
                  onChange={handlenamefilterchnage}
                  value={selectedhotelid}
                  placeholder='Enter Hotel Name'
                  data={hotelsFList}
                  block
                />
              </div>
              <hr style={{ opacity: '1' }} />
              <div className='widget widget_price_filter'>
                <h3 className='form-label'>Price Filter</h3>
                <RangeSlider
                  onChange={value => setRangeValue(value)}
                  value={rangeValue} // Bind state to the slider
                  min={minimumPrice} // Minimum value
                  max={maximumPrice} // Maximum value
                  step={1} // Step increment for the slider
                />
              </div>
              <hr style={{ opacity: '1' }} />
              <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide bwCBvh kuQtDu mt-3'>
                  <h3 className='fMYYEI'>Filter by Rating</h3>
                  {/* <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i> */}
                </div>
                <div className='edRKIj gCLia-d'>
                  <ul className='bzqoba '>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          onChange={() => handleStarsChange(5)}
                          checked={selectedStars.includes(5)}
                          name='rating5'
                          value='5'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          onChange={() => handleStarsChange(4)}
                          checked={selectedStars.includes(4)}
                          name='rating4'
                          value='4'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          onChange={() => handleStarsChange(3)}
                          checked={selectedStars.includes(3)}
                          name='rating3'
                          value='3'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          onChange={() => handleStarsChange(2)}
                          checked={selectedStars.includes(2)}
                          name='rating2'
                          value='2'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          onChange={() => handleStarsChange(1)}
                          checked={selectedStars.includes(1)}
                          name='rating1'
                          value='1'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className='widget widget_has_radio_checkbox'
                style={{ borderBottom: '0' }}
              >
                <div className='filter-show-hide'>
                  <h3>Meal Type</h3>
                  {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                <div className='mb-2'>
                  <ul>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          checked={selectedMeals.includes('ROOM ONLY')}
                          onChange={() => handleMealTypeChange('ROOM ONLY')}
                          name='meal1'
                          value='ROOM ONLY'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> Room Only</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          checked={selectedMeals.includes('BED AND BREAKFAST')}
                          onChange={() =>
                            handleMealTypeChange('BED AND BREAKFAST')
                          }
                          name='meal2'
                          value='BED AND BREAKFAST'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> BED AND BREAKFAST </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          checked={selectedMeals.includes('HALF BOARD')}
                          onChange={() => handleMealTypeChange('HALF BOARD')}
                          name='meal3'
                          value='HALF BOARD'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> HALF BOARD</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type='checkbox'
                          className='custom-textbox'
                          checked={selectedMeals.includes('Full BOARD')}
                          onChange={() => handleMealTypeChange('Full BOARD')}
                          name='meal4'
                          value='Full BOARD'
                        />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'> Full BOARD</span>
                      </label>
                    </li>
                  </ul>
                  {/* <button style={{width:'96%'}} onClick={MealFilter} className='btn btn-warning m-2'>Apply</button> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={ResetFilter} className='btn btn-warning me-2'>
            Reset Filter
          </button>
          <Button onClick={applyAllFilters} appearance='primary'>
            Apply
          </Button>
          <Button onClick={handleClose} appearance='subtle'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Layout>
        <div className='container-fluid'>
          <div className='mt-3 px-3'>
            <HotelSearch />
          </div>
        </div>
        <div className='m-4'>
          <div className='row'>
            <div className='col-md-3 col-md-pull-9'>
              <div>
                <div class='mobile-Filter-info mb-3'>
                  <ul>
                    <li>
                      <p onClick={() => setFilterOpen(true)}>
                        <FontAwesomeIcon icon={faFilter} />
                        <span> Filters</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className='page-sidebar'>
                  <div
                    id='map-container-google-2'
                    className='z-depth-1-half map-container'
                    style={{ height: '500' }}
                  >
                    <iframe
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                    &q=${searchData.destination_name}`}
                      frameborder='0'
                      allowfullscreen
                      className='rounded-4'
                    ></iframe>
                  </div>
                </div>
                <div className='page-sidebar hide-page_filter '>
                <hr style={{ opacity: '1' }} />
                  <div className='widget widget_price_filter'>
                    <h3 className='form-label'>Order By</h3>
                    <select
                      value={orderBy}
                      onChange={handleOrderTypeChange}
                      class='form-select'
                    >
                      <option value='1'>Low to High</option>
                      <option value='2'>Highy to Low</option>
                      <option value='3'>Order by A-Z</option>
                      <option value='4'>Order by Z-A</option>
                    </select>
                  </div>
                  <hr style={{ opacity: '1' }} />
                  <div className='widget widget_price_filter'>
                    <h3 className='form-label'>Search by property name</h3>
                    <SelectPicker
                      onChange={handlenamefilterchnage}
                      value={selectedhotelid}
                      placeholder='Enter Hotel Name'
                      data={hotelsFList}
                      block
                    />
                  </div>
                  <hr style={{ opacity: '1' }} />
                  <div className='widget widget_price_filter'>
                    <h3 className='form-label'>Price Filter</h3>
                    <RangeSlider
                      onChange={value => setRangeValue(value)}
                      value={rangeValue} // Bind state to the slider
                      min={minimumPrice} // Minimum value
                      max={maximumPrice} // Maximum value
                      step={1} // Step increment for the slider
                    />
                  </div>
                  <hr style={{ opacity: '1' }} />
                  <div className='widget widget_has_radio_checkbox'>
                    <div className='filter-show-hide bwCBvh kuQtDu mt-3'>
                      <h3 className='fMYYEI'>Filter by Rating</h3>
                      {/* <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i> */}
                    </div>
                    <div className='edRKIj gCLia-d'>
                      <ul className='bzqoba '>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={() => handleStarsChange(5)}
                              checked={selectedStars.includes(5)}
                              name='rating5'
                              value='5'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={() => handleStarsChange(4)}
                              checked={selectedStars.includes(4)}
                              name='rating4'
                              value='4'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={() => handleStarsChange(3)}
                              checked={selectedStars.includes(3)}
                              name='rating3'
                              value='3'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={() => handleStarsChange(2)}
                              checked={selectedStars.includes(2)}
                              name='rating2'
                              value='2'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              onChange={() => handleStarsChange(1)}
                              checked={selectedStars.includes(1)}
                              name='rating1'
                              value='1'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'>
                              <i className='fa fa-star'>
                                {' '}
                                <FontAwesomeIcon icon={faStar} />
                              </i>
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className='widget widget_has_radio_checkbox'
                    style={{ borderBottom: '0' }}
                  >
                    <div className='filter-show-hide'>
                      <h3>Meal Type</h3>
                      {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                    </div>
                    <div className='mb-2'>
                      <ul>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              checked={selectedMeals.includes('ROOM ONLY')}
                              onChange={() => handleMealTypeChange('ROOM ONLY')}
                              name='meal1'
                              value='ROOM ONLY'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Room Only</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              checked={selectedMeals.includes(
                                'BED AND BREAKFAST'
                              )}
                              onChange={() =>
                                handleMealTypeChange('BED AND BREAKFAST')
                              }
                              name='meal2'
                              value='BED AND BREAKFAST'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> BED AND BREAKFAST </span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              checked={selectedMeals.includes('HALF BOARD')}
                              onChange={() =>
                                handleMealTypeChange('HALF BOARD')
                              }
                              name='meal3'
                              value='HALF BOARD'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> HALF BOARD</span>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type='checkbox'
                              className='custom-textbox'
                              checked={selectedMeals.includes('Full BOARD')}
                              onChange={() =>
                                handleMealTypeChange('Full BOARD')
                              }
                              name='meal4'
                              value='Full BOARD'
                            />
                            <i className='awe-icon awe-icon-check'></i>
                            <span className='rating'> Full BOARD</span>
                          </label>
                        </li>
                      </ul>
                      <p
                        onClick={ResetFilter}
                        className='text-primary mt-3 text-end cursor-pointer'
                      >
                        Reset Filter?
                      </p>
                      <button
                        className='btn btn-primary btn-block select-styling search-btn1'
                        onClick={applyAllFilters}
                      >
                        Apply
                      </button>
                      {/* <button style={{width:'96%'}} onClick={MealFilter} className='btn btn-warning m-2'>Apply</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-9 col-md-push-3 mt-3'>
              <div className='filter-page__content'>
                <div className='fw-bold mb-2'>
                  {' '}
                  Showing {startHotelIndex} to {endHotelIndex} of{' '}
                  {hotelsList.length} hotels
                </div>
                <div className='filter-item-wrapper' id='tours_filter'>
                  {currentHotels.map((item, index) => (
                    <div key={item.index} className='row px-3'>
                      <div className='col-md-12 mb-4 uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme'>
                        <div className='row'>
                          <div className='col-md-4 item-from px-0'>
                            <div class='uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative'>
                              <figure class='uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio'>
                                <span>
                                  <span>
                                    {/* <p className="text-center show-provider">{item.hotel_provider}</p> */}
                                    <div class='uitk-gallery-carousel'>
                                      <h3 class='is-visually-hidden'>
                                        Photo gallery for Swissôtel Al Maqam
                                        Makkah
                                      </h3>
                                      <div class='uitk-gallery-carousel-items ratio-21-9'>
                                        <div
                                          class='uitk-gallery-carousel-item uitk-gallery-carousel-item-current'
                                          aria-hidden='false'
                                        >
                                          <figure class='uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio'>
                                            <div class='uitk-image-placeholder'>
                                              {hotelsDetail[item.hotel_id] &&
                                              hotelsDetail[item.hotel_id]
                                                .details_data &&
                                              hotelsDetail[item.hotel_id]
                                                .details_data.image ? (
                                                <div>
                                                  <img
                                                    class='uitk-image-media '
                                                    src={
                                                      hotelsDetail[
                                                        item.hotel_id
                                                      ].details_data.image
                                                    }
                                                    alt=''
                                                  />
                                                </div>
                                              ) : (
                                                // <ShimmerThumbnail height={250} />
                                                <div
                                                  class='uitk-gallery-carousel-item uitk-gallery-carousel-item-current'
                                                  aria-hidden='false'
                                                >
                                                  <figure class='uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio'>
                                                    <div class='uitk-image-placeholder'></div>
                                                    <button
                                                      type='button'
                                                      data-testid='uitk-gallery-item-current-trigger'
                                                      class='uitk-image-link'
                                                    >
                                                      <span class='is-visually-hidden'>
                                                        Room
                                                      </span>
                                                    </button>
                                                  </figure>
                                                </div>
                                              )}
                                            </div>
                                          </figure>
                                        </div>
                                        {/* <div class="uitk-gallery-carousel-paging-controls">
                                      <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                                        <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="prev-button-lodging-card-gallery-carousel-16249642-1-title">Show previous image for Swissôtel Al Maqam Makkah</title>
                                          <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                        </svg>
                                      </button>
                                      <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                                        <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="next-button-lodging-card-gallery-carousel-16249642-1-title">Show next image for Swissôtel Al Maqam Makkah</title>
                                          <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                        </svg>
                                      </button>
                                    </div> */}
                                      </div>
                                    </div>
                                  </span>
                                </span>
                              </figure>
                              {item.hotel_provider === 'Custome_hotel' && (
                                <div class='uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute'>
                                  <div data-stid='outlined-save-button'>
                                    <div class='favorite-button-wrapper'>
                                      <button
                                        type='button'
                                        aria-label='Save Swissôtel Al Maqam Makkah to a trip'
                                        class='favorite-button favorite-button-button-no-label'
                                      >
                                        HH
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-8'>
                            <div class='uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium'>
                              <div class='uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between'>
                                <div class='uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item'>
                                  <div class='uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two'>
                                    <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-rows uitk-layout-grid-display-grid uitk-layout-flex-item'>
                                      <h3
                                        class='uitk-heading uitk-heading-5 overflow-wrap uitk-layout-grid-item uitk-layout-grid-item-has-row-start'
                                        onClick={() =>
                                          RoomDetailPage(item.hotel_id, index)
                                        }
                                      >
                                        {' '}
                                        {item.hotel_name}
                                      </h3>
                                      <p className='card-star mt-1'>
                                        {item.stars_rating === '' ? (
                                          <span className='fw-bold'>
                                            No Rating
                                          </span>
                                        ) : (
                                          Array(item.stars_rating)
                                            .fill(0)
                                            .map((_, index) => (
                                              <i
                                                key={index}
                                                className='fa fa-star'
                                              >
                                                <FontAwesomeIcon
                                                  icon={faStar}
                                                />
                                              </i>
                                            ))
                                        )}
                                      </p>
                                      <h6 className='departure-date my-2'>
                                        {moment(searchData.check_in).format('ll')}{' '}
                                        <FontAwesomeIcon icon={faArrowRight} />{' '}
                                        {moment(searchData.check_out).format('ll')}
                                      </h6>
                                    </div>
                                    <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end'></div>
                                  </div>
                                  <div
                                    class='uitk-text uitk-text-spacing-half truncate-lines-2 uitk-type-300 uitk-text-default-theme'
                                    aria-hidden='false'
                                  >
                                    <div class='uitk-text uitk-type-200 uitk-type-bold uitk-text-default-theme py-2'>
                                      {hotelsDetail[item.hotel_id] && (
                                        <div style={{ fontSize: '14px' }}>
                                          <i class='awe-icon awe-icon-marker-2'>
                                            <FontAwesomeIcon icon={faGlobe} />
                                          </i>{' '}
                                          {
                                            hotelsDetail[item.hotel_id]
                                              .details_data.address
                                          }{' '}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div class='uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x'>
                                    <div class='uitk-text uitk-type-200 uitk-text-default-theme'>
                                      {hotelsDetail[item.hotel_id] ? (
                                        <div class='row'>
                                          {hotelsDetail[
                                            item.hotel_id
                                          ].details_data.facilities.map(
                                            (facility, index) => (
                                              <div
                                                key={index}
                                                className=' col-sm-4 col-4 col-md-4 col-lg-4 mt-1'
                                              >
                                                <div class='single-tour-feature d-flex align-items-center'>
                                                  <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                                    <i class='fas fa-check'>
                                                      <FontAwesomeIcon
                                                        icon={faCheck}
                                                      />
                                                    </i>
                                                  </div>
                                                  <div class='single-feature-titles'>
                                                    <p class='title fw-bold'>
                                                      {facility}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div className='placeholder-glow'>
                                          <span className='placeholder col-12'></span>
                                          <span className='placeholder col-12'></span>
                                          <span className='placeholder col-6'></span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex'>
                                  <div class='uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start'>
                                    <h6>
                                      <super>
                                        {priceConversion === false
                                          ? item.hotel_curreny
                                          : Object.keys(SelectedCurrRates)
                                              .length === 0
                                          ? BaseCurrRates.base_code
                                          : SelectedCurrRates.base_code}{' '}
                                        {priceConversion === false
                                          ? (
                                              calculateMarkup(
                                                item.customer_markup_type,
                                                item.customer_markup,
                                                item.admin_markup_type,
                                                item.admin_markup,
                                                item.min_price
                                              ) / Number(numberOfNights)
                                            ).toFixed(2)
                                          : (
                                              renderPrice(
                                                calculateMarkup(
                                                  item.customer_markup_type,
                                                  item.customer_markup,
                                                  item.admin_markup_type,
                                                  item.admin_markup,
                                                  item.min_price
                                                ),
                                                item.hotel_curreny
                                              ) / Number(numberOfNights)
                                            ).toFixed(2)}{' '}
                                        / Night
                                      </super>
                                    </h6>
                                    <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-'>
                                      <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one'>
                                        <div class='uitk-layout-flex uitk-layout-flex-align-items-center'>
                                          <span class='uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard'>
                                            <span
                                              class='uitk-badge-base-text'
                                              aria-hidden='true'
                                            >
                                              <div>
                                                <i class='awe-icon awe-icon-marker-2'>
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                  />
                                                </i>{' '}
                                                {item.rooms_options.length}{' '}
                                                {item.rooms_options.length===1?'Room':'Rooms'} Available{' '}
                                              </div>
                                            </span>
                                          </span>
                                        </div>
                                        <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item'></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end'>
                                    <div
                                      class='uitk-layout-flex uitk-layout-flex-flex-direction-column'
                                      data-test-id='price-summary'
                                    >
                                      <div className='text-center'>
                                        <div
                                          class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row justify-content-center uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                          data-test-id='price-summary-message-line'
                                        >
                                          <div class='uitk-spacing uitk-spacing-padding-block-half'>
                                            <div class='uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden'>
                                              The price is
                                              {priceConversion ? (
                                                <h6>
                                                  <super>
                                                    {priceConversion === false
                                                      ? item.hotel_curreny
                                                      : Object.keys(
                                                          SelectedCurrRates
                                                        ).length === 0
                                                      ? BaseCurrRates.base_code
                                                      : SelectedCurrRates.base_code}{' '}
                                                    {renderPrice(
                                                      calculateMarkup(
                                                        item.customer_markup_type,
                                                        item.customer_markup,
                                                        item.admin_markup_type,
                                                        item.admin_markup,
                                                        item.min_price
                                                      ),
                                                      item?.hotel_curreny
                                                    )}
                                                  </super>
                                                </h6>
                                              ) : (
                                                <h6>
                                                  <super>
                                                    {item?.hotel_curreny}{' '}
                                                    {calculateMarkup(
                                                      item.customer_markup_type,
                                                      item.customer_markup,
                                                      item.admin_markup_type,
                                                      item.admin_markup,
                                                      item.min_price
                                                    )}
                                                  </super>
                                                  {/* <sub>PP</sub> */}
                                                </h6>
                                              )}
                                            </div>
                                            <span aria-hidden='true'>
                                              <div class='uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                <p>
                                                  <super>
                                                    {priceConversion === false
                                                      ? item.hotel_curreny
                                                      : Object.keys(
                                                          SelectedCurrRates
                                                        ).length === 0
                                                      ? BaseCurrRates.base_code
                                                      : SelectedCurrRates.base_code}{' '}
                                                    {priceConversion === false
                                                      ? calculateMarkup(
                                                          item.customer_markup_type,
                                                          item.customer_markup,
                                                          item.admin_markup_type,
                                                          item.admin_markup,
                                                          item.min_price
                                                        )
                                                      : renderPrice(
                                                          calculateMarkup(
                                                            item.customer_markup_type,
                                                            item.customer_markup,
                                                            item.admin_markup_type,
                                                            item.admin_markup,
                                                            item.min_price
                                                          ),
                                                          item.hotel_curreny
                                                        )}
                                                  </super>
                                                  {item.min_price_Actual !=
                                                    null &&
                                                    item.min_price_Actual !==
                                                      0 && (
                                                      <sub
                                                        style={{
                                                          color: '#808080ad'
                                                        }}
                                                      >
                                                        <del>
                                                          {priceConversion ===
                                                          false
                                                            ? item.hotel_curreny
                                                            : Object.keys(
                                                                SelectedCurrRates
                                                              ).length === 0
                                                            ? BaseCurrRates.base_code
                                                            : SelectedCurrRates.base_code}{' '}
                                                          {priceConversion ===
                                                          false
                                                            ? calculateMarkup(
                                                                item.customer_markup_type,
                                                                item.customer_markup,
                                                                item.admin_markup_type,
                                                                item.admin_markup,
                                                                item.min_price_Actual
                                                              )
                                                            : renderPrice(
                                                                calculateMarkup(
                                                                  item.customer_markup_type,
                                                                  item.customer_markup,
                                                                  item.admin_markup_type,
                                                                  item.admin_markup,
                                                                  item.min_price_Actual
                                                                ),
                                                                item.hotel_curreny
                                                              )}
                                                        </del>
                                                      </sub>
                                                    )}
                                                </p>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                        <small className='text-center'>Vat and Taxes included</small>

                                        <div
                                          class='uitk-layout-flex uitk-layout-flex-align-items-center justify-content-center uitk-layout-flex-flex-direction-row  uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                          data-test-id='price-summary-message-line'
                                        ></div>
                                        <div
                                          class='uitk-layout-flex uitk-layout-flex-align-items-center justify-content-center uitk-layout-flex-flex-direction-row  uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                          data-test-id='price-summary-message-line'
                                        >
                                          {/* <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div> */}
                                          <button
                                            onClick={() =>
                                              RoomDetailPage(
                                                item.hotel_id,
                                                index
                                              )
                                            }
                                            className='btn btn-primary'
                                          >
                                            {
                                              // Check if all rooms have request_type === 1
                                              item.rooms_options.every(
                                                room =>
                                                  room.request_type === '1'
                                              )
                                                ? 'Book on request' // If all have request_type === 1
                                                : ' Book now' // If any room doesn't have request_type === 1
                                            }
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <h6 className='departure-date mb-0'>
                          {searchData.checkin}{' '}
                          <FontAwesomeIcon icon={faArrowRight} />{' '}
                          {searchData.checkout}
                        </h6> */}
                          {/* <button
                          className='btn btn-primary select-styling search-btn1 form-control'
                          onClick={() => RoomDetailPage(item.hotel_id, index)}
                        >
                          Book Now
                        </button> */}
                          <a
                            rel='noopener'
                            data-stid='open-hotel-information'
                            target='_blank'
                            onClick={() => RoomDetailPage(item.hotel_id, index)}
                            class='uitk-card-link'
                          >
                            <span class='is-visually-hidden'>
                              Opens Swissôtel Al Maqam Makkah in new tab
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {hotelsList.length >= 15 && (
                  <div className='text-center mt-4'>
                    <p>
                      Showing {startHotelIndex} to {endHotelIndex} of{' '}
                      {hotelsList.length} hotels
                    </p>
                    <nav aria-label='Page navigation example'>
                      <ul class='pagination'>
                        {/* Previous Button */}
                        {currentPage !== 1 && (
                          <li
                            class='page-item '
                            onClick={() => paginate(validCurrentPage - 1)}
                          >
                            <a class='page-link' href='#'>
                              Previous
                            </a>
                          </li>
                        )}

                        {/* Pagination Items */}
                        {renderPaginationItems()}

                        {/* Next Button */}
                        {currentPage !== pagesCount && (
                          <li
                            class='page-item'
                            onClick={() => paginate(validCurrentPage + 1)}
                          >
                            <a class='page-link' href='#'>
                              Next
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Hotels
